import { useEffect } from 'react';
import { PurchaseLegarooPlansMutation } from '@roo_apollo/_hooks.generated';

export const useGTM = () => {
  useEffect(() => {
    const gtmId = process.env.REACT_APP_GTM_ID || 'GTM-KVWWRMTX';
    if (!gtmId) return;

    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    const noScript = document.createElement('noscript');
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);
  }, []);
};

export const trackEvent = (event: string, ecommerce: any) => {
  if (!window?.dataLayer) return;
  window.dataLayer.push({ event, ecommerce });
};
const extractDuration = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = Math.abs(end.getTime() - start.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
export const normaliseTransactionData = (
  data: PurchaseLegarooPlansMutation
) => {
  const purchase = data?.purchaseLegaroo?.result;
  if (!purchase) return null;

  const transactionId = purchase[0].refNumber;
  let transactionTotal = 0;

  const items = purchase.map(traveler => {
    transactionTotal += traveler.total;
    return {
      // eslint-disable-next-line @typescript-eslint/camelcase
      item_name: 'Legaroo PLAN Student',
      // eslint-disable-next-line @typescript-eslint/camelcase
      item_id: `${traveler.planType}-${extractDuration(
        traveler.startDate,
        traveler.endDate
      )}`,
      price: traveler.total / 100,
      quantity: 1,
      destinations: traveler.destinations.map(
        destination => destination.country.name
      ),
      currency: 'USD',
    };
  });

  return {
    event: 'purchase',
    ecommerce: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      transaction_id: `${transactionId}-${purchase.length}`,
      value: transactionTotal / 100,
      currency: 'USD', // o lo que uses
      items,
    },
  };
};
